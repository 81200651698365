// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-greetings-js": () => import("./../src/pages/greetings.js" /* webpackChunkName: "component---src-pages-greetings-js" */),
  "component---src-pages-idea-js": () => import("./../src/pages/idea.js" /* webpackChunkName: "component---src-pages-idea-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outline-js": () => import("./../src/pages/outline.js" /* webpackChunkName: "component---src-pages-outline-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

